<template>
  <div class="sign-up-wrap-email purple-bg py-13">
    <div class="small-container">
      <div class="sign-up-wrap-email--logo">
        <img src="@/assets/images/general/yellow-logo.svg"/>
      </div>
      <div class="sign-up-wrap--email" v-if="!done">
        <h2 class="h2-style">{{ __t('forgot-password.title') }}</h2>
        <form @submit.prevent="handleReset" @input="validateForm">
          <div class="form-group">
            <label for="email">{{ __t('forgot-password.email') }}</label>
            <input type="text" class="form-control" @input="validateEmail(user.email)" :class="{ 'is-invalid': !validEmail }" required v-model="user.email" id="email" aria-describedby="email" />
            <small class="form-text invalid-feedback">{{ __t('forgot-password.not-valid-email') }}.</small>
          </div>
          <div class="yellow-button">
            <button type="submit" class="btn-default" :disabled="!valid">{{ __t('forgot-password.submit') }}</button>
          </div>
        </form>
      </div>
      <div class="sign-up-wrap--email" v-else>
        <p v-text="message"></p>
      </div>
      <h5 class="h5-style-lg">
        {{ __t('forgot-password.dont-have-account-1') }}
        <router-link :to="{ name: 'SignUpEmail' }">here</router-link>.
        {{ __t('forgot-password.dont-have-account-2') }}
      </h5>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      valid: false,
      user: {
        email: null
      },
      validEmail: true,
      done: false,
      message: null
    }
  },
  methods: {
    ...mapActions('auth', ['forgotPassword']),
    handleReset () {
      this.forgotPassword(this.user).then((resp) => {
        this.message = resp.message
        this.done = true
      }).catch((resp) => {
        this.message = resp.message
        this.done = true
      })
    },
    validateEmail (email) {
      // eslint-disable-next-line
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.validEmail = re.test(email)
    },
    validateForm () {
      this.valid = this.validEmail
    }
  }
}
</script>
